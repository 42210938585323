<form class="form" (ngSubmit)="save()">
  <div class="course-info">
    <div class="ui-input">
      <span>
        Course Name
      </span>
      <input type="text" [(ngModel)]="courseInfo.CourseName" name="course-name">
    </div>

    <div class="ui-input">
      <span>
        Course Rating
      </span>
      <input type="text" [(ngModel)]="courseInfo.CourseRating" name="course-rating">
    </div>

    <div class="ui-input">
      <span>
        Slope Rating
      </span>
      <input type="text" [(ngModel)]="courseInfo.SlopeRating" name="slope-rating">
    </div>

    <div class="ui-input">
      <span>
        Grass Type
      </span>
      <input type="text" [(ngModel)]="courseInfo.GrassType" name="grass-type">
    </div>

    <div class="ui-input">
      <span>
        Course Designer
      </span>
      <input type="text" [(ngModel)]="courseInfo.CourseDesigner" name="course-designer">
    </div>
    <div class="save">
      <button type="submit">Save</button>
    </div>
  </div>
</form>
