import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GolfHomeComponent } from './golf/golf-home/golf-home.component';
import { HomeComponent } from './home/home.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'golf', component: GolfHomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
