import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-golf-home',
  templateUrl: './golf-home.component.html',
  styleUrls: ['./golf-home.component.scss']
})
export class GolfHomeComponent implements OnInit {


  constructor() { }

  ngOnInit(): void {
  }


}
