// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyA_V2pZqw5srhpNYgwt9ofKhI223WXeP_s",
    authDomain: "shooter-9f778.firebaseapp.com",
    projectId: "shooter-9f778",
    storageBucket: "shooter-9f778.appspot.com",
    messagingSenderId: "367071412939",
    appId: "1:367071412939:web:109f2511a8e8746383d14e",
    measurementId: "G-86LD95974Y"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
