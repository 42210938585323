import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GolfHomeComponent } from './golf-home/golf-home.component';
import { CourseInfoComponent } from './course-info/course-info.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [GolfHomeComponent, CourseInfoComponent],
  imports: [
    CommonModule,
    FormsModule
  ]
})
export class GolfModule { }
