import { Component, OnInit } from '@angular/core';
import { initializeApp } from '@firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-course-info',
  templateUrl: './course-info.component.html',
  styleUrls: ['./course-info.component.scss']
})
export class CourseInfoComponent implements OnInit {

  public courseInfo: CourseInfo = new CourseInfo();

  private app;
  private db;

  constructor() {
    this.app = initializeApp(environment.firebaseConfig);
    this.db = getFirestore(this.app);
   }

  async ngOnInit(): Promise<void> {
    let courseInfoList =  await this.getCourseInfo();
    console.log(courseInfoList);
  }

  async getCourseInfo(): Promise<CourseInfo[]>{
    const courseInfoCollection = collection(this.db, 'course-info');
    const courseInfoSnapShot = await getDocs(courseInfoCollection);
    const courseInfoList = courseInfoSnapShot.docs.map(doc => doc.data() as CourseInfo);
    return courseInfoList;
  }

  save(): void{

    console.log(JSON.stringify(this.courseInfo));
  }


}

export class CourseInfo{
  CourseName: string;
  CourseRating: number;
  SlopeRating: number;
  GrassType: string;
  CourseDesigner: string;
}
